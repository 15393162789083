import Footer from "./components/Footer";
import Header from "./components/Header"
import { Link } from "react-scroll";

function App() {

  return (
    <>
    <Header/>
    <section id="home" className="w-[100%] h-[100vh] overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full z-1 overflow-hidden">
    <div className="w-0 h-0 absolute bottom-0
    xs:border-t-[200px]
    xs:border-l-[400px]
   minxs:border-t-[250px]
   minxs:border-l-[500px] 
   sm:border-t-[300px]
   sm:border-l-[900px] 
    border-t-transparent
   border-l-[#049372]
    "></div>
    <div className="w-0 h-0 absolute bottom-0 right-0
    xs:border-t-[300px]
    xs:border-r-[300px]
    minxs:border-t-[400px]
    minxs:border-r-[400px]
    minsm:border-t-[600px]
    minsm:border-r-[500px]
    minmd:border-t-[700px]
    minmd:border-r-[700px]
    minlg:border-t-[800px]
    minlg:border-r-[900px]
    minxl:border-t-[1000px]
    minxl:border-r-[1100px]
     border-t-transparent
 border-r-[#E97451]
    "></div>
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center text-[#282c37]">
    <h1 className="sm:text-[40px] minsm:text-[50px]  text-[#049372] drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] minxs:whitespace-nowrap">Decentralized Applications<br/> ecosystem</h1>
    <h2 className="font-[500] sm:text-[16px] minsm:text-[28px]  minsm:whitespace-nowrap mb-3">"Unite, Empower, and Sustain with Every Click<br/>Welcome to Decosystem.app"</h2>
    </div>
    <div className="absolute flex gap-4 bottom-0 left-[50%] translate-x-[-50%] items-end translate-y-[-50%] text-center">
        <div className="w-[3px] h-4 bg-[#282c37] cursor-pointer"></div>
        <div className="w-[3px] h-8 bg-[#8283a7] cursor-pointer"></div>
        <div className="w-[3px] h-4 bg-[#282c37] cursor-pointer"></div>
    </div>
    <div className="lg:hidden absolute left-[30px] bottom-[50%] translate-x-[50%] translate-y-[50%] z-10 cursor-pointer">
    <svg className="absolute translate-x-[-100%] left-[5px] top-[50%] translate-y-[-50%] w-[30px]" fill="none" stroke="#bdc0d2" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path strokeLinecap="square" strokeLinejoin="square" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"></path>
    </svg>
      <div className="border-[#bdc0d2] border-[2px] rounded-[50%] w-[60px] h-[60px] z-10 text-center hover:bg-[#E97451] transition-all">
        <h2 className="pt-4 text-[#bdc0d2] font-[400] leading-4">PR<br/>EV</h2>
      </div>
    </div>
    <div className="lg:hidden absolute right-[30px] bottom-[50%] translate-x-[-50%] translate-y-[50%] z-10 cursor-pointer">
    <svg className="absolute translate-x-[100%] right-[4px] top-[50%] translate-y-[-50%] w-[30px]" fill="none" stroke="#bdc0d2" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path strokeLinecap="square" strokeLinejoin="square" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
    </svg>
      <div className="border-[#bdc0d2] border-[2px] rounded-[50%] w-[60px] h-[60px] z-10 text-center hover:bg-[#ffffff] transition-all">
        <h2 className="pt-4 text-[#bdc0d2] font-[400] leading-4 ">NE<br/>XT</h2>
      </div>
    </div>
    </div>
    </section>
    <section id="future" className="max-w-[1400px] mx-auto overflow-hidden bg-white py-10">
        <h1 className="text-[#282c37] uppercase sm:text-[32px] minsm:text-[40px] text-center max-w-[660px] mx-auto relative before:absolute before:w-[50px] before:h-[2px] before:bg-[#282c37] before:left-[50%] before:translate-x-[-50%] before:top-[-10px]">MISSION & VISION</h1>
        <div className="flex sm:flex-wrap minsm:flex-nowrap mx-auto gap-[60px] mt-10 text-[#141414] container">
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
            <div className="w-[100px] h-[100px] flex justify-center items-center rounded-[50%] bg-[#049372] border-[2px] border-[#8283a7] mx-auto mb-10 relative after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:translate-y-[100%] after:w-[2px] after:h-[30px] after:bg-[#8283a7]">
              <img alt="paperairplane" className="object-cover w-[40px]" src="/images/paperairplane.png"/>
            </div>
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px]">MISSION</h3>
            <p className="text-[#383a3f] text-[14px] leading-6">Our Mission is to create a sustainable, decentralized future.</p>
          </div>
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
            <div className="w-[100px] h-[100px] flex justify-center items-center rounded-[50%] bg-[#e1eef6] border-[2px] border-[#8283a7] mx-auto mb-10 relative after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:translate-y-[100%] after:w-[2px] after:h-[30px] after:bg-[#8283a7]">
              <img alt="lab" className="object-cover w-[40px]" src="/images/lab.png"/>
            </div>
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px]">VISION</h3>
            <p className="text-[#383a3f] text-[14px] leading-6">Decosystem envisions the future of decentralized applications contributing to our  economy </p>
          </div>
        </div>
    </section>
    <section id="decSocialMedia" className="w-[100%] py-10">
      <h1 className="text-[#282c37] uppercase sm:text-[34px] minsm:text-[40px] text-center max-w-[660px] mx-auto relative before:absolute before:w-[50px] before:h-[2px] before:bg-[#282c37] before:left-[50%] before:top-[-10px] before:translate-x-[-50%]">Decentralized Social Media</h1>
      <div className="flex sm:flex-wrap minsm:flex-nowrap minsm:justify-center mx-auto gap-[60px] mt-10 text-[#141414] container">
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
            <div className="w-[100px] h-[100px] flex justify-center items-center rounded-[50%] bg-[#e1eef6] border-[2px] border-[#8283a7] mx-auto mb-10 relative after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:translate-y-[100%] after:w-[2px] after:h-[30px] after:bg-[#8283a7]">
              <img alt="blockchat" className="object-cover w-[40px]" src="/images/chat.png"/>
            </div>
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px]">BLOCKCHAT</h3>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Blockchat is an innovative communication protocol designed for use across multiple blockchains, providing a comprehensive range of features such as text, voice, and video calls within a Web3 environment. Unlike traditional Web2 communication platforms, Blockchat leverages blockchain technology to store messages and group chats directly on-chain, ensuring immutability and transparency.
            </p>
            <br/>
            <p className="text-[#383a3f] text-[14px] leading-6">
            One of the core priorities of Blockchat is privacy. It incorporates robust encryption techniques to secure interactions, making it a reliable choice for users who prioritize confidentiality and security in their communications. Additionally, Blockchat facilitates peer-to-peer audio and video calls via the blockchain network, further enhancing the security of such communications by leveraging the inherent security features of blockchain technology.
            </p>
          </div>
      </div>
      <div className="flex sm:flex-wrap minsm:flex-nowrap minsm:justify-center mx-auto gap-[60px] mt-10 text-[#141414] container">
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
            <div className="w-[200px] h-[200px] mt-8 flex justify-center items-center mx-auto mb-14 relative after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:translate-y-[100%] after:w-[2px] after:h-[30px] after:bg-[#8283a7]">
              <img alt="blockchat" className="object-cover w-[200px]" src="/images/harmony.webp"/>
            </div>
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px]">HARMONY</h3>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Harmony's mission is to create a decentralized social platform that combines the advantages of blockchain technology with familiar social networking experiences, offering users greater control, privacy, and opportunities for participation and rewards.
            </p>
            <br/>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Harmony is a multichain decentralized social platform that aims to combine the benefits of blockchain technology with social networking features. Here are some key aspects and benefits of Harmony:
            </p>
            <h4 className="mb-2 mt-2 font-[700] text-[14px] tracking-[2px]">Multichain Architecture:</h4>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Harmony operates on a multichain architecture, allowing it to leverage multiple blockchains for various functionalities. This can enhance scalability, interoperability, and flexibility within the platform.
            </p>
            <h4 className="mb-2 mt-2 font-[700] text-[14px] tracking-[2px]">Decentralization:</h4>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Being decentralized means that Harmony doesn't rely on a single central authority to manage user data or control the platform. This decentralization can lead to increased transparency, censorship resistance, and user control over their data.
            </p>
            <h4 className="mb-2 mt-2 font-[700] text-[14px] tracking-[2px]">Social Networking Features:</h4>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Harmony integrates typical social networking features such as user profiles, messaging, content sharing, and community creation. Users can connect with each other, share content, and engage in discussions within the platform.
            </p>
            <h4 className="mb-2 mt-2 font-[700] text-[14px] tracking-[2px]">Blockchain Integration:</h4>
            <p className="text-[#383a3f] text-[14px] leading-6">
            By leveraging blockchain technology, Harmony can offer features like token incentives, content monetization, digital asset ownership, and secure transactions. Users may earn tokens for participating in the platform, supporting content creators, or contributing valuable content.
            </p>
            <h4 className="mb-2 mt-2 font-[700] text-[14px] tracking-[2px]">Privacy and Security:</h4>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Harmony prioritizes privacy and security by using encryption techniques for communications, ensuring data integrity through blockchain immutability, and providing users with control over their privacy settings and data sharing.
            </p>
            <h4 className="mb-2 mt-2 font-[700] text-[14px] tracking-[2px]">Interoperability:</h4>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Harmony's multichain approach enables interoperability with other blockchain platforms and protocols. This can facilitate seamless asset transfers, cross-platform interactions, and access to a broader ecosystem of decentralized applications (dApps) and services.
            </p>
            <h4 className="mb-2 mt-2 font-[700] text-[14px] tracking-[2px]">Community Governance:</h4>
            <p className="text-[#383a3f] text-[14px] leading-6">
            Harmony will use same utility token implement a governance model where users can participate in decision-making processes regarding platform upgrades, feature implementations, and protocol changes. This fosters a sense of community ownership and empowerment.
            </p>
          </div>
      </div>
    </section>
    <section id="circularEconomy" className="max-w-[1400px] mx-auto overflow-hidden bg-white py-10">
        <h1 className="text-[#282c37] uppercase sm:text-[32px] minsm:text-[40px] text-center max-w-[660px] mx-auto relative before:absolute before:w-[50px] before:h-[2px] before:bg-[#282c37] before:left-[50%] before:translate-x-[-50%] before:top-[-10px]">CIRCULAR ECONOMY</h1>
        <img alt="circular economy" className="max-w-[220px] w-[100%] object-cover object-center mx-auto my-8" src="/images/circularEconomy.webp" />
        <p className="text-[#383a3f] text-[14px] leading-6 text-center">
        Circular Economy project is innovative project which support ecology and healthy way of life.
        </p>
        <div className="flex sm:flex-wrap minsm:flex-wrap minsm:justify-center mx-auto gap-[30px] mt-10 text-[#141414] container">
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
            <div className="w-[100px] h-[100px] flex justify-center items-center rounded-[50%] bg-[#e1eef6] border-[2px] border-[#8283a7] mx-auto mb-10 relative after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:translate-y-[100%] after:w-[2px] after:h-[30px] after:bg-[#8283a7]">
              <img alt="lab" className="object-cover w-[40px]" src="/images/lab.png"/>
            </div>
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px]">VISION</h3>
            <p className="text-[#383a3f] text-[14px] leading-6">To become world global platform for sustainable eco system of tokenized circulating economy.</p>
          </div>
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
            <div className="w-[100px] h-[100px] flex justify-center items-center rounded-[50%] bg-[#049372] border-[2px] border-[#8283a7] mx-auto mb-10 relative after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:translate-y-[100%] after:w-[2px] after:h-[30px] after:bg-[#8283a7]">
              <img alt="paperairplane" className="object-cover w-[40px]" src="/images/paperairplane.png"/>
            </div>
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px]">MISSION</h3>
            <p className="text-[#383a3f] text-[14px] leading-6">Companies and individuals actively practicing healthy ways of life and green circular economy with use of blockchain technology and tokenization.</p>
          </div>
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
          <p className="text-[#383a3f] text-[14px] leading-6 text-center">
          Project which is funded by companies to offer their goods and services to our users, which will be awarded with tokens for  buying products and using services as part of the Circular economy.  
          All users can have accounts on our platform and will be able to use their digital wallets to receive awards.
          </p>
          <p className="text-[#383a3f] text-[14px] leading-6 text-center">
          Circular Economy platform is offering to companies to become members of new and innovative Digital Green Marketing. Companies can purchase packages and to offer product and services. 
          </p>
          <div className="px-1 mt-6">
            <p className="text-[#383a3f] text-[14px] leading-6 text-left">
            Circular Economy project will support:
            <br/>
            -healthy, and green products
            <br/>
            -products with recycling packages
            <br/>
            -services that support healthy ways of life, ecology, sport and recreation and entrepreneurship
            </p>
          </div>
          </div>
        </div>
    </section>
    <section id="dappsMarketplace" className="w-[100%] py-10">
      <h1 className="text-[#282c37] uppercase sm:text-[34px] minsm:text-[40px] text-center max-w-[660px] mx-auto relative before:absolute before:w-[50px] before:h-[2px] before:bg-[#282c37] before:left-[50%] before:top-[-10px] before:translate-x-[-50%]">DApps Marketplace</h1>
      <div className="flex sm:flex-wrap minsm:flex-nowrap minsm:justify-center mx-auto gap-[60px] mt-10 text-[#141414] container">
          <div className="text-center sm:w-[100%] minsm:w-[50%]">
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px]">DApps Marketplace</h3>
            <p className="text-[#383a3f] text-[14px] leading-6">
            DApps across categories like finance, gaming, education, and more. 
            </p>
          </div>
      </div>
    </section>
    <section id="work" className="2xl:max-w-[100%] max-w-[1535px] mx-auto overflow-hidden bg-white py-6">
        <div className="flex flex-wrap gap-6 mt-10 justify-center lg:justify-center">
          <div className="text-center sm:w-[100%] minsm:w-[33%] bg-[#049372] rounded-2xl p-8">
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px] text-[#fff]">Multi-Blockchain Support:  </h3>
            <p className="text-[#fff] text-[16px] leading-6">Information on interoperability and how the platform operates across multiple EVM blockchains such as Ethereum, Avalanche, Polygon, BSC and many more.</p>
          </div>
          <div className="text-center sm:w-[100%] minsm:w-[33%] bg-[#049372] rounded-2xl p-8">
            <h3 className="mb-2 font-[700] text-[18px] tracking-[2px] text-[#fff]">Utility Token: </h3>
            <p className="text-[#fff] text-[16px] leading-6">Deep dive into the token's uses, such as transactions, governance, rewards, and access to premium features.</p>
          </div>
        </div>
        <button className="uppercase hidden mx-auto mt-10 text-white text-[12px] bg-[#8a0403] py-2 px-10 leading-4 rounded-[25px] transition-all hover:bg-[#9f0202]">Load more</button>
    </section>
    <section id="about" className="w-[100%] py-10">
      <h1 className="text-[#282c37] uppercase sm:text-[34px] minsm:text-[40px] text-center max-w-[660px] mx-auto relative before:absolute before:w-[50px] before:h-[2px] before:bg-[#282c37] before:left-[50%] before:top-[-10px] before:translate-x-[-50%]">ABOUT US</h1>
      <div className="mt-10 flex minlg:justify-center">
        <div className="lg:w-[100%] minlg:w-[50%] bg-[#049372] xs:p-3 minxs:p-10 minlg:p-20 text-[#fbfbfb]">
          <p className="mt-4">
          Imagine stepping into a vibrant digital realm where freedom, creativity, and commerce converge in harmony. 
Welcome to our decentralized social platform—a pioneering space that empowers individuals from all walks of life to connect, express, and innovate without boundaries. Here, you're not just a user; you're a co-creator of an ever-evolving ecosystem.
</p>
          <p className="mt-10 mb-4">At the heart of our platform lies a marketplace unlike any other. Picture a bustling digital bazaar, where artists, entrepreneurs, and visionaries showcase their creations and services. Whether it's unique digital art, innovative solutions, or bespoke experiences, our marketplace thrives on the diversity and ingenuity of its community members. 
Transactions are secure, transparent, and direct, thanks to the power of blockchain technology, ensuring that creators are rewarded fairly for their contributions.</p>
        </div>
      </div>
    </section>
    <Link spy={true} activeClass="hidden" className="cursor-pointer fixed z-[100] right-[20px] bottom-[20px] bg-[#E97451] rounded-[50%] p-4" to="home" smooth={true} offset={-80}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
      </svg>
    </Link>
    <Footer/>
    </>
  );
}

export default App;
