import { useState } from "react";
import { Link } from "react-scroll";
import {slide as Menu} from "react-burger-menu";
function Header(){

    const [navbar, setNavbar] = useState(false);
    const [activeMobileNav, setActiveMobileNav] = useState(false);

    const handleMobileNav = () => {
        setActiveMobileNav((prev)=>!prev);
    }

    const changeBackground = () => {
        if(window.scrollY >= 100){
            setNavbar(true)
        }else{
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground);

    return (
        <header className={"z-[102] relative top-0 " + (navbar ? "sticky bg-white transition-all animate-show-in" : "minmd:bg-transparent minmd:animate-hide-out md:bg-white")}>
            <nav>
                <ul className="hidden minlg:flex list-none text-[#141414] font-[400] justify-center gap-8 uppercase max-w-[1200px] items-center flex-wrap mx-auto text-141414 text-[14px]">
                    <li >
                        <ul className="flex gap-5">  
                            <li><Link spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative" to="home" smooth={true} offset={-80}>Home</Link></li>
                            <li><Link spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative" to="future" smooth={true} offset={-80} >Mission & Vision</Link></li>
                            <li><Link spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative" to="decSocialMedia" smooth={true} offset={-80} >Decentralized Social Media</Link></li>
                        </ul>
                    </li>
                    <li><img src="/images/mainLogoTransparent.png" className="w-[100px] h-[100px] object-cover"/></li>
                    <li >
                        <ul className="flex gap-5">
                            <li><Link spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative" to="circularEconomy" smooth={true} offset={-80} >Circular Economy</Link></li>
                            <li><Link spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative" to="dappsMarketplace" smooth={true} offset={-80} >DApps Marketplace</Link></li>
                            <li><Link spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative" to="about" smooth={true} offset={-80} >About Us</Link></li>
                        </ul>
                    </li>   
                </ul>
                <div className="minlg:hidden">
                <img src="/images/mainLogoTransparent.png" className="w-[80px] h-[80px] ml-5 object-cover"/>

                <Menu right isOpen={ activeMobileNav } onOpen={handleMobileNav} onClose={handleMobileNav}>
                <Link onClick={handleMobileNav} spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative menu-item" to="home" smooth={true} offset={-80}>Home</Link>
                <Link onClick={handleMobileNav} spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative menu-item" to="future" smooth={true} offset={-80} >Mission & Vision</Link>
                <Link onClick={handleMobileNav} spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative menu-item" to="decSocialMedia" smooth={true} offset={-80} >Decentralized Social Media</Link>
                <Link onClick={handleMobileNav} spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative menu-item" to="circularEconomy" smooth={true} offset={-80} >Circular Economy</Link>
                <Link onClick={handleMobileNav} spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative menu-item" to="dappsMarketplace" smooth={true} offset={-80} >DAppsMarketplace</Link>
                <Link onClick={handleMobileNav} spy={true} activeClass="text-[#8283a7] before:absolute before:bottom-[-5px] before:left-0 before:h-[1px] before:w-[30px] before:bg-[#8283a7]" className="cursor-pointer relative menu-item" to="about" smooth={true} offset={-80} >About Us</Link>
                </Menu>
                </div>

            </nav>
            
        </header>
    );
}

export default Header;